.form-control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid grey;
  padding: 10px 15px;
  margin: 10px 0px;
  font-size: 14px;
}
